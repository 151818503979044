<template>


  <div class="container" >
    <div class='banner '>
      <div class="header-logo">
      </div>
      </div>
      <!-- 礼尚往来 -->
      <div class="box">
        <div class="product-title">
          <p class="title">礼尚往来</p>
          <div class="desc">- COURTESY DEMANDS RECIPROCITY -</div>
        </div>
        <div class="product-content ">
          <el-row  type="flex"  justify="space-between" style="width: 100%;">
            <el-col :span="11" v-for="(item,index) in productList" :key="index">
              <div class="niupai-container" @mouseover="showLihe(index)" :class="[!item['isShowDetail']?'niupai-preview':'']" >
                  <div class="niupai-desc" v-if="!item['isShowDetail']" >
                      <p class="niupai-title">{{ item['title'] }}</p>
                      <p class="niupai-subtitle">{{ item['subTitle'] }}</p>
                  </div>
                <transition :enter-active-class="item['enterClass']" :leave-active-class="item['leaveClass']">
                <div class="niupai-list-bg" v-if="item['isShowDetail']"   >
                  <p class="title">{{ item['product'] }}</p>
                  <p class="price">¥{{ item['price'] }}</p>
                  <div>
                    <div class="niupai-list-content" v-for="(product,i2) in item['list']" :key="i2">
                      <span>{{ product['name'] }}</span>
                      <span >*{{ product['num'] }}</span>
                    </div>
                  </div>
                  <p class="niupai-list-hj">{{ item['total'] }}</p>
                </div>
              </transition>
              </div>

            </el-col>
            <!-- <el-col :span="11">
              <div class="niupai-container" style="position: relative;">
                <div class="niupai-list-bg">
                  <p class="title">益伍益食</p>
                  <p class="price">¥988</p>
                  <div>
                    <div class="niupai-list-content">
                      <span>菲力牛排</span>
                      <span >*1</span>
                    </div>
                    <div class="niupai-list-content">
                      <span>西冷店</span>
                      <span >*1</span>
                    </div>
                    <div class="niupai-list-content">
                      <span>牛上脑片</span>
                      <span >*2</span>
                    </div>
                    <div class="niupai-list-content">
                      <span>牛肋排</span>
                      <span >*1</span>
                    </div>
                  </div>
                  <p class="niupai-list-hj">合1500克</p>
                </div>
                <div class="lajiao"></div>
              </div>
            </el-col> -->
          </el-row>


        </div>
      </div>
      <!-- 澳洲和牛 -->
      <div class="">
        <div class="product-title">
          <p class="title">澳洲和牛</p>
          <div class="desc">- AUSTRALIA STEAK -</div>
        </div>
        <div class="product-content fl-product">
            <el-row type="flex" justify="space-between"  style="width: 100%;">
                <div class="pc-article"   v-for="(item,index) in articleList" :key="index" >
                  <div class="article-pre"  @mouseover="showArticleDetail(index)"  v-if="!item['isShowDetail']"  :style="{'background':`url(${item['img']}) no-repeat center` }" ><p>{{ item['title'] }}</p></div>
                  <div class="detail" v-if="item['isShowDetail']" @mouseleave="showArticleDetail(index)" >
                    <div class="bg">
                      <div class="title">{{ item['subTitle'] }}</div>
                      <div class="line"></div>
                      <div class="content">
                            <p v-for="(content,ci) in item['content']" :key="ci">{{ content }}</p>
                      </div>
                    </div>
                  </div>
                </div>

            </el-row>



        </div>
      </div>
      <!-- 精品溯源 -->
      <div class="">
        <div class="product-title">
          <p class="title">精品溯源 美味健康</p>
          <div class="desc">- TRACEABILITY VIDEO -</div>
        </div>
        <div class="product-content ">
            <el-row :gutter="24" style="width: 100%;">

            <div  v-for="(item,i) in videoList" :key="i" >
              <el-col :span="6" v-if="item['list'].length==2" >
                <div class="video-two" >
                  <div class="item" :style="{'background':`url(${video['previewImg']}) no-repeat center` }" v-for="(video,index) in item['list']" :key="index" @click="playVideo(video['videoUrl'])">
                    <img class="player-start" src="http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E8%A7%86%E9%A2%91/play.png" alt="">
                    <!-- <img :src="video['previewImg']" alt=""> -->
                    <div class="video-title">{{ video['title'] }}</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" v-else  class="video-one"  key="play" >
                    <vue-core-video-player  ref="myVideo" :cover="defaultPreviewImg"  :src="videoUrl"></vue-core-video-player>
              </el-col>

            </div>
          </el-row>

        </div>
      </div>
      <!-- 海外工厂 -->
      <div class="">
        <div class="product-title">
          <p class="title">海外工厂</p>
          <div class="desc">- overseas factory -</div>
        </div>
        <div class="product-content ">
          <el-row :gutter="24">
            <el-col :span="16">
              <div class="factory-left" style="padding: 0 !important;">
                <div v-for="(item,index) in factoryLogo" :key="index" class="item"  :style="{'background-image':`url(${item})  `  }"></div>
              </div>
            </el-col>
            <el-col :span="8">
              <el-carousel class="factory-right" style="padding: 0 !important;" :height="'500'">
                <el-carousel-item v-for="item in factoryBannerList" :key="item" >
                  <img :src="item" alt="">
                </el-carousel-item>
              </el-carousel>
            </el-col>
          </el-row>

          <!-- class="factory-right" -->

        </div>
      </div>
      <p class="beian">©️2024 上海益渡食品有限公司  沪ICP备09021577号-1 </p>
  </div>
</template>

<script>
import 'animate.css';
// import HeaderLogo from '/public/svg/header-logo.svg'
export default {
  name: 'Pc',
  props: {},
  components:{},
  data () {
    return {
      clientWidth:0,
      clientHeight:0,
      isMobile: false,
      factoryLogo: [
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/1.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/2.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/3.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/4.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/5.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/6.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/7.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/8.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/9.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/10.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/11.png',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/12.png',
      ],
      factoryBannerList: [
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/banner/1.jpg',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/banner/2.jpg',
        'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/banner/3.jpg',
      ],
      videoUrl: 'https://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E8%A7%86%E9%A2%91/jbs6.mp4',
      defaultPreviewImg: 'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E7%BC%A9%E7%95%A5%E5%9B%BE/%E5%9B%BE%E5%B1%82%2018.png',
      videoList: [
        {
          'list': [
            {
              'title': '澳大利亚TEYS工厂',
              'videoUrl': 'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E8%A7%86%E9%A2%91/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A%E5%AE%8C%E6%88%90.mp4',
              'previewImg':'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E7%BC%A9%E7%95%A5%E5%9B%BE/%E5%9B%BE%E5%B1%82%2031.png'
            },
            {
              'title': '阿根廷牛排',
              'videoUrl': 'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E8%A7%86%E9%A2%91/%E9%98%BF%E6%A0%B9%E5%BB%B7%E5%AE%8C%E6%95%B4.mp4',
              'previewImg':'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E7%BC%A9%E7%95%A5%E5%9B%BE/%E5%9B%BE%E5%B1%82%2020.png'
            },
          ]
        },
        {
          'list': [
              {
              'title': '美国JBS肉食加工商',
              'videoUrl': 'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E8%A7%86%E9%A2%91/jbs6.mp4',
              'previewImg':'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E7%BC%A9%E7%95%A5%E5%9B%BE/%E5%9B%BE%E5%B1%82%2018.png'
            },
          ]
        },
        {
          'list': [
                {
                'title': '美味牛排',
                'videoUrl': 'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E8%A7%86%E9%A2%91/%E5%AE%8C%E6%88%90%E8%A7%86%E9%A2%911.mp4',
                'previewImg':'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E7%BC%A9%E7%95%A5%E5%9B%BE/%E5%9B%BE%E5%B1%82%2021.png'
              },
              {
                'title': '美味牛排',
                'videoUrl': 'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E8%A7%86%E9%A2%91/%E5%AE%8C%E6%95%B4%E8%A7%86%E9%A2%912.mp4',
                'previewImg':'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E7%BC%A9%E7%95%A5%E5%9B%BE/%E5%9B%BE%E5%B1%82%2022.png'
              }
          ]
        }
      ],
      articleList: [
        {
          title: '菲力',
          subTitle: '菲力牛排',
          isShowDetail:false,
          img:'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/fl-short.jpg',
          content: [
            '菲力牛排来自牛身背部的长条肌肉，位于脊椎骨与肋骨之间，是牛身上最嫩的肉之一。由于这个部位几乎不用于运动，肌肉纹理细腻，肉质柔嫩。',
            '菲力牛排通常具有一条垂直贯穿肉块的脂肪带，这条脂肪带使菲力牛排更加鲜嫩多汁，并为其赋予特殊的口感。',
            '为了保持菲力牛排的嫩滑口感，通常采用高温快煎的方式，将肉块两面煎至金黄色，内部保持着粉红色的中度熟。',
            '菲力牛排的肉质细腻，口感柔软，带有浓郁的牛肉香气。它富含天然的肉汁和脂肪，在烹饪过程中会释放出丰富的味道和香气。',
            '菲力牛排通常搭配各种美味的酱汁(如黑椒汁、红酒汁等)或香草黄油，以增添口感和风味。它可以搭配烤薯条、烤蔬菜或新鲜的沙拉等作为主菜享用。'
          ]
        },
        {
          title: '西冷',
          subTitle: '西冷店',
          isShowDetail:false,
          img:'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/xl.jpg',
          content: [
            '西冷刺来自牛身上的腰部，是肋骨和脊椎之间最嫩的肉之一。肉质较细腻，具有一定的弹性和咀嚼感。',
            '西冷牛排中的脂肪分配比较均匀，在烹饪过程中会逐渐融化，使肉质更加鲜嫩多汁。',
            '西冷牛排通常烤至中熟或稍熟，保留一定的肉汁和粉红色的内部，这样能够保持肉质的柔嫩口感。',
            '西冷急富含蛋白质、铁、锌、钾等营养成分，可以提供人体所需的能量，同时也有助于增强肾脏和提高身体的代谢率。',
            '西冷牛排可以搭配各种香辣酱、沙拉或烤蔬菜，以增添味道和风味。在不同的时候，可以先用刀子将肉灼灼，然后再搭配其他配菜一起享用。'
          ]
        },
        {
          title: '战斧',
          subTitle: '战斧斧',
          isShowDetail:false,
          img:'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/zf.jpg',
          content: [
            '战斧是从肋骨上切下的整块迅，包括长长的肉块和涂抹在骨头上的大块脂肪。这种切割方式使得迅呈现出锤子的形状，因此得名“战斧”。',
            '战斧镐的骨头非常大，通常保留至少5英寸的骨头长度。这不仅为镐增添了独特的外观，也赋予了镐更加的肉香和质感。',
            '战斧刺的肉质鲜嫩多汁，纹理紧致。由于整块刺的厚度增加，烹饪时可以保持内部的嫩度和汁液，同时外部形成美味的焦糖化外层。',
            '战斧牛排通常采用高温烘烤的制作方式，制作外部脆皮，内部保持粉红色或中度熟。这样可以保持肉质的鲜嫩口感和肉汁的丰富。',
            '战斧牛排满足了东南亚的牛肉香味和丰富的口感，是一道令人赏心悦目的美食。它可以搭配各种调味酱、蘑菇、烤蔬菜或土豆等作为主菜享用。'
          ]
        },
        {
          title: '板腱',
          subTitle: '板刺激',
          isShowDetail:false,
          img:'http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/bj.jpg',
          content: [
            '板腱是牛身上比较常见的部位之一，位于前腿下方。将板腱肌肉外侧厚度片，这种切割法使得肉质更加嫩滑，质感更加丰富。切片中间纤维透明的筋是其招牌。其肉质平坦菲力。',
            '板触发器可以用煎、烤、炸等方式烹制。其中，煎和烤是比较常见的做法。',
            '在烹饪板急切时，要注意不要过度烤制，否则肉质会变得干燥。同时，用刀子在牛排表面划出几道口子，让肉质更容易入味。',
            '板触发器可以搭配各种酱汁、沙拉或烤蔬菜。在替换时，可以用刀子评价小块后搭配其他配菜一起享用，味道更佳。'
          ]
        }
      ],
      productList: [
        {
          title: '经典礼盒',
          subTitle: 'CLASSIC GIFT BOX',
          product: '益伍益食',
          price: '988',
          isShowDetail: false,
          enterClass:'animate__animated animate__bounceInLeft ',
          leaveClass:'animate__animated animate__bounceOutLeft ',
          list: [
            {name:'菲力牛排',num:'1'},
            {name:'西冷店',num:'1'},
            {name:'牛上脑片',num:'2'},
            {name:'牛肋排',num:'1'},
          ],
          total:'合1500克'
        },
        {
          title: '经典礼盒',
          subTitle: 'CLASSIC GIFT BOX',
          product: '益伍益食',
          price: '988',
          isShowDetail: false,
          enterClass:'animate__animated animate__bounceInRight ',
          leaveClass:'animate__animated animate__bounceOutRight ',
          list: [
            {name:'菲力牛排',num:'1'},
            {name:'西冷店',num:'1'},
            {name:'牛上脑片',num:'2'},
            {name:'牛肋排',num:'1'},
          ],
          total:'合1500克'
        }
      ]
    }
  },
  //创建数据代理&数据检测
  beforeCreate () { },
  created () { },
  //挂载
  beforeMount () { },
  mounted () {
    if (this.checkMobile()) {
      this.isMobile = true;
    }
  },
  //更新
  beforeUpdate () { },
  updated () { },
  //销毁
  beforeDestroy () { },
  destroyed () { },

  //方法
  methods: {
    checkMobile() {
      let flag = navigator.userAgent.match(    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    playVideo (url) {
      this.videoUrl = url;
      this.$refs.myVideo[0].play()
    },
    showArticleDetail (i) {

      this.articleList[i].isShowDetail = !this.articleList[i].isShowDetail

    },
    showLihe (i) {
      console.log(i);
      this.productList[i].isShowDetail = true
    },
    hideLihe (i) {
      this.productList[i].isShowDetail = false
    }
  }
}
</script>


<style lang="scss" >
body {
  margin: 0;
  color: #ffffff;
}
p{
  margin: 0;
  padding: 0;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.banner {
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/bg.jpg") no-repeat center;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  height: calc(100vh);
}
.header-logo{
  width: 40%;
  height: 250px;
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/logo.png") no-repeat center;
  background-size: contain;
}
.box {
  padding: 0 2rem;
}
.box-mobile{
  padding: 0 20px;
}
.product-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.product-title .title{
  font-size: 35px;
  color: #ffffff;

  margin-top: 70px;
}
.product-title .desc{
  text-align: center;
  margin-top: 70px;
  font-size: 24px;
  font-family: CircularStd, CircularStd-Medium;
  font-weight: bold;
  color: #aaaaaa;
  text-transform: uppercase;
}
.product-content {
  display: flex;
  width: 100%;
}
.lajiao{
  width: 120px;
  height: 170px;
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/lajiao.png") no-repeat center;
  position: absolute;
  bottom: -3rem;
  right:-0.2rem
}
.mobile-width{
  width:100% !important;
}
.product-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 78px 0;
  flex-wrap:wrap;
}
.niupai-container{
  /* width: 100%; */
  height: 759px;
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/niupai-bg.jpg") no-repeat center;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  cursor:pointer;
}
.niupai-preview{
  // justify-content: flex-end;
  .article-pre{
    display: none;
  }
}
.niupai-container-mobile{
  width:100%;
  height:  379.5px;
  margin-bottom: 20px;
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/niupai-bg.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
  align-items: center;
}
.niupai-desc{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 80px;
  position: absolute;
  bottom: 0;
}
.niupai-desc-mobile{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
}
.niupai-title {
  font-size: 36px;
  color: #993333;
}
.niupai-subtitle{
  font-size: 24px;
  color: #333333;
  margin-top: 20px;
}
.niupai-list-bg{
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/niu-bg.png") no-repeat center;
  width: 80%;
  height: 80%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  text-align: center;

}
.niupai-list-bg .title{
  font-size: 48px;
  font-family: GlowSansSC, GlowSansSC-Normal-Medium;
  font-weight: bold;
  color: #333333;
  margin-top: 70px;
}
.niupai-list-bg .price{
  margin-top: 30px;
  font-size: 36px;
  font-family: CircularStd, CircularStd-Medium;
  font-weight: bold;
  color: #932929;
}
.niupai-list-content{
  height: 70px;
  margin: 0 125px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 24px;
  font-family: GlowSansSC, GlowSansSC-Normal-Medium;
  font-weight: bold;
  color: #333333;

  border-bottom: #c1bebe 1px solid;
}
.niupai-list-content span{
  display: block;
}
.niupai-list-content :last-child{
  color: #932929 !important;
}
.niupai-list-hj{
  margin-top: 55px;
  font-size: 18px;
  font-family: GlowSansSC, GlowSansSC-Normal-Medium;
  color: #333333;
}
.header-logo-svg{
  width:100%;
  height: 100%;
}
.beian{
 font-size: 1rem;
 color: #ffffff;
 text-align: center;
}
.pc-article{
  cursor:pointer;
  .detail{
    background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/fl.jpg") no-repeat center;
    background-size: cover;
    height: 811px;
    width: 720px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bg{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center ;
    background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/niu-bg.png") no-repeat center;
    height: 90%;
    width: 90%;
    background-size: cover;
    .title{
      font-size: 36px;
      color: #993333;
      // margin-top: 52px;
    }
    .line{
      margin: 20px 0;
      border: 1px solid #993333;
      width: 67px;
    }
    .content p{
      padding: 20px 70px;
      color: #333333;
      font-size: 16px;
      font-family: GlowSansSC, GlowSansSC-Normal-Medium;
      font-weight: bold;
      text-align: justifyLeft;
      color: #333333;
      line-height: 28px;
      text-indent : 36px;
      text-align: left;
    }
  }

}

.fl-content{
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/niu-bg.png") no-repeat center;
  /* width: 626px; */
  height: 704px;
  background-size: cover;
}
.fl-desc{
  text-align: left;
  color: #333333;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.fl-title{
  font-size: 36px;
  color: #993333;
  margin-top: 52px;
}
.fl-line{
  margin: 20px 0;
  border: 1px solid #993333;
  width: 67px;
}
.fl-text p{
  padding: 20px 70px;
  color: #333333;
  font-size: 16px;
  font-family: GlowSansSC, GlowSansSC-Normal-Medium;
  font-weight: bold;
  text-align: justifyLeft;
  color: #333333;
  line-height: 28px;
  text-indent : 36px;
}

.contentActive .fl{
  display: block;
}
.article-pre{
  width: 320px;
  height: 811px;
  background-size: cover !important;
  position: relative;

}


.article-pre p{
  position: absolute;
  top: 70px;
  /* left: 26px; */
  left: 86px;
  font-size: 36px;
  /* font-size: 86px; */
  font-family: GlowSansSC, GlowSansSC-Normal-Medium;
  font-weight: bold;
  text-align: left;
  color: #fefefe;
}
.video-one{
  /* width: 1000px; */
  height: 560px;

}
.video-one .item{
  position: relative;
}
.video-two{
  height: 560px;
  /* width: 336px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.video-two .item{
  height: 260px;
  width: 100%;
  /* border: 1px solid #A51E1E; */
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* background-size: contain; */
}
.video-two .video-player{
  /* width: 336px; */
}
.video-one .video-player{
  /* width: 1000px; */
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/vedio/%E7%BC%A9%E7%95%A5%E5%9B%BE/%E5%9B%BE%E5%B1%82%2018.png") no-repeat center;

}
.video-two .video-title{
  font-size: 16px;
  font-family: GlowSansSC, GlowSansSC-Normal-Medium;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 16px;
}
.video-one .video-title{
  font-size: 30px;
  font-family: GlowSansSC, GlowSansSC-Normal-Medium;
  width: 100%;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 40px;
}
.player-start{
  width: 68px;
  height: 68px;
  /* position: absolute;
  left: 134px; top: 96px; */
}


.factory-left{
  /* width: 940px; */
  height: 380px;
  display: flex;
  padding: 60px;
  background: url("http://ywys-static.oss-cn-beijing.aliyuncs.com/pc/assets/factory-logo/bg.jpg") no-repeat center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

}
.factory-left .item {
  width: 180px;
  height: 92px;
  background-size:contain;
  background-repeat: no-repeat;
  background-color: #FFF;
  background-position: center;
  flex-basis: 21%;

}
.factory-right{
  /* width: 680px; */
  height: 380px;
}
.swiper {
  margin: 0;
}


/* video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {
    display: none;
}
video::-webkit-media-controls-timeline {
    display: none;
}
video::-webkit-media-controls-current-time-display{
    display: none;
}
video::-webkit-media-controls-time-remaining-display {
    display: none;
}
video::-webkit-media-controls-mute-button {
    display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}
video::-webkit-media-controls-volume-slider {
    display: none;
}
video::-webkit-media-controls-enclosure{
    display: none;
} */
</style>


