import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import VueCoreVideoPlayer from 'vue-core-video-player'
import VideoPlayer from 'vue-video-player'
// window.videojs = VideoPlayer.videojs;
import videojs from "video.js";
require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
Vue.config.productionTip = false
Vue.use(VueCoreVideoPlayer)
Vue.use(VideoPlayer)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')