import Vue from 'vue'
import VueRouter from 'vue-router'
import Mobile from '../views/Mobile.vue'
import Pc from '../views/Pc.vue'

Vue.use(VueRouter)

const routesP = [
  {
    path: '/',
    name: 'Index',
    component: Pc
  },
]
const routesM = [
  {
    path: '/',
    name: 'Index',
    component: Mobile
  },
]
let routes = [];
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  routes = routesM
} else {
  routes = routesP
}
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
